@import "../../assets/scss/variables";

.mt-0 {
  margin-top: 0 !important;
}

.footer-copyright {
  background-color: black;
  padding-top: 90px;
  min-height: 181px;
  width: 100%;
  text-align: center;
}

.footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}

.footer-copyright nav ul li:last-child {
  padding-right: 0;
}

.footer-copyright p {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-size: 0.9em;
}

.footer-copyright.footer-copyright-style-2 {
  background: #212529;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-copyright.footer-copyright-border-grey {
  border-top: 1px solid #e5e5e5;
}

.footer-copyright.footer-copyright-full-width-border-top {
  position: relative;
  border-top: 0;
}

.footer-copyright.footer-copyright-full-width-border-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.footer-nav.footer-nav-bottom-line nav > ul:not(:hover) > li > a.active {
  color: #0088cc !important;
}

.footer-nav.footer-nav-bottom-line nav > ul:not(:hover) > li > a.active:before {
  background: #0088cc !important;
}

.footer-nav.footer-nav-bottom-line nav > ul:hover > li:hover > a {
  color: #0088cc !important;
}

.footer-nav.footer-nav-bottom-line nav > ul:hover > li:hover > a:before {
  background: #0088cc !important;
}

.container .footer-text div {
  color: white !important;
  font-size: 0.8rem;
  cursor: pointer;
}

.footer-logo {
  display: block;
}

footer {
  min-height: 181px;
  padding: 0px !important;
}

a:not(.btn) {
  color: #777;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.footer-logo a {
  text-decoration: none !important;
}

.footer-logo img:hover {
  cursor: pointer;
}

.name-next-to-logo {
  color: white;
  font-size: 20px;
  padding: 15px 5px;
  vertical-align: middle;
  font-weight: 600;
}
