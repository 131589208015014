// colors:
$navbar-color: #343a40;
$page-color: #a0a5ac;
$form-color: white;

// screen sizes:
$mobile-small-size: 576px;
$mobile-large-size: 768px;
$tablet-size: 992px;
$desktop-size: 1200px;
