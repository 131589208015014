@import "./variables";

// screen <= 576px
@mixin mobile-small-range {
  @media (max-width: $mobile-small-size) {
    @content;
  }
}

// 577px =< screen <= 768px
@mixin mobile-large-range {
  @media (min-width: $mobile-small-size + 1) and (max-width: $mobile-large-size) {
    @content;
  }
}

// screen <= 768px
@mixin mobile-range {
  @media (max-width: $mobile-large-size) {
    @content;
  }
}

// screen <= 992px
@mixin mobile-tablet-range {
  @media (max-width: $tablet-size) {
    @content;
  }
}

// 767px <= screen <= 992px
@mixin tablet-range {
  @media (min-width: $mobile-large-size + 1) and (max-width: $tablet-size) {
    @content;
  }
}

// 1201px < screen
@mixin desktop-range {
  @media (min-width: $desktop-size + 1) {
    @content;
  }
}
