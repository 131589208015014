@import "./variables";
@import "./mixins";

// page
.whole-page-body {
  padding: 0px;
  background-color: $page-color;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  margin-bottom: -181px;
}
.whole-page-body:after {
  content: "";
  display: block;
}
.mt-0,
.whole-page-body:after {
  height: 181px;
}
.page-below-navigation {
  margin-top: 50px;
  width: 100vw;
  min-height: 70vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 12px;
  flex-shrink: 0;
  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

// navigation bar
.container-navbar {
  padding: 0px;
  width: 100vw;
  position: fixed;
  background-color: $navbar-color;
  min-height: 61px;
  z-index: 2;
  .navbar-item {
    .active {
      color: green !important;
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .logo-spinner {
    animation: spin 10s infinite;
    margin: 2px 5px 2px 2px;
  }
}

// landing
.container-landing {
  text-align: center;
  margin-top: 10px;
  .image {
    padding: 10px;
    background-color: black;
    .carousel-inner {
      height: 470px !important;
    }
    .logo-image {
      min-height: 370px;
      max-height: 370px;
    }
    .carousel-indicators {
      position: relative !important;
      z-index: 1;
    }
    .carousel-caption {
      position: relative;
      z-index: 1;
      top: 10px;
      left: 0px;
    }
  }
  .header-text {
    padding: 20px;
    font-size: 36px;
    font-weight: bold;
    color: white;
    background-color: green;
  }
  .sign {
    padding: 70px;
    .btn {
      margin-right: 40px;
    }
  }
}

// map legend
.container-legend-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}
.container-legend-sticky-wrapper {
  position: absolute;
  background-color: white;
  line-height: 1;
  p {
    margin: 10px 10px 10px 2px;
    padding-right: 25px;
    position: relative;
    span {
      position: absolute;
      right: 0px;
      top: -7px;
    }
  }
  z-index: 10;
}
.container-legend-sticky .container-legend-sticky-inner {
  position: fixed;
  background-color: white;
  margin: 0;
  padding: 0;
  line-height: 1;
  p {
    margin: 10px 10px 10px 2px;
    padding-right: 25px;
    position: relative;
    span {
      position: absolute;
      right: 0px;
      top: -7px;
    }
  }
  z-index: 10;
}
.container-info-window {
  &:hover {
    cursor: pointer;
  }
}

// page not found
.page-not-found {
  &-with-nav-bar {
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 550px;
    color: rgb(70, 66, 66);
    h1 {
      font-size: 50px;
      font-weight: 900;
      line-height: normal;
    }
    &-container {
      width: 100%;
      margin-top: 50px;
      background-color: white;
    }
  }
  &-link {
    display: block;
  }
}

.account-label {
  padding-top: 15px;
}

.loading-spinner {
  height: 100vh;
  text-align: center;
  margin: 40% 0;
}

// login and signup
.container-form-login,
.container-form-signup {
  min-height: 74vh;
  background-color: $form-color;
  padding: 60px;
}

// create part modal
.container-form-create-part {
  padding: 20px 90px;
  text-align: center;
}

// service modal
.container-form-service {
  padding: 20px 90px;
  text-align: center;
}

// edit user modal
.container-form-edit-user {
  padding: 20px 90px;
  text-align: center;
  .form-group {
    select {
      display: block;
      width: 300px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}

// motor modal
.container-form-motor {
  padding: 20px 90px;
  text-align: center;
  .form-group {
    select {
      display: block;
      width: 300px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}

// report modal
.container-form-report {
  padding: 20px 90px;
  text-align: center;
  .form-group {
    text-align: left;
  }
}
.container-reports-list {
  .no-reports {
    margin-left: 5px;
  }
  .rc-pagination {
    margin: 10px 15px 10px 15px;
    &-item-active {
      background-color: lightgreen !important;
    }
  }
}

// marker modal
.container-form-marker {
  padding: 20px 90px;
  text-align: center;
  .form-group {
    select {
      display: block;
      width: 300px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}

// path modal
.container-form-path {
  padding: 20px 90px;
  text-align: center;
  .form-group {
    select {
      display: block;
      width: 300px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}

// services
.container-services {
  min-height: 80vh;
  background-color: white;
  padding-top: 10px;
  overflow-x: auto;
  padding: 10px;
  .rc-pagination {
    margin: 10px 15px 10px 15px;
    &-item-active {
      background-color: lightgreen !important;
    }
  }
  .loading-spinner {
    height: 100%;
    text-align: center;
    margin: 20% 0;
  }
  .service-button {
    background-color: lightgreen;
    padding: 5px;
    border: 2px solid grey;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .container-search-services {
    padding-top: 10px;
    .container-search-form {
      display: inline-flex;
      input {
        height: 38px;
        border: 2px solid grey;
        border-radius: 5px;
      }
      .form-group {
        margin-right: 10px;
      }
    }
  }
  .table {
    &-bordered {
      border: 2px solid grey !important;
      td,
      th {
        border: 1px solid grey !important;
      }
    }
    .thead {
      background-color: lightgreen;
    }
    &-button {
      background-color: lightgreen;
      border-radius: 5px;
    }
    &-action-button {
      background-color: lightskyblue;
      border-radius: 5px;
    }
  }
}

// parts
.container-parts {
  min-height: 80vh;
  background-color: white;
  padding-top: 10px;
  overflow-x: auto;
  padding: 10px;
  .rc-pagination {
    margin: 10px 15px 10px 15px;
    &-item-active {
      background-color: lightgreen !important;
    }
  }
  .loading-spinner {
    height: 100%;
    text-align: center;
    margin: 20% 0;
  }
  .part-button {
    background-color: lightgreen;
    padding: 5px;
    border: 2px solid grey;
    border-radius: 5px;
    margin-bottom: 5px;
    height: 38px;
  }
  .image-view {
    cursor: pointer;
  }
  .icon-pdf {
    margin: 0 25%;
    color: #ea4c3a;
  }
  .container-search-parts-filter {
    padding-top: 10px;
    .container-search-form {
      display: inline-flex;
      input {
        height: 38px;
        border: 2px solid grey;
        border-radius: 5px;
      }
      .form-group {
        margin-right: 10px;
      }
    }
  }
  .table {
    &-bordered {
      border: 2px solid grey !important;
      td,
      th {
        border: 1px solid grey !important;
      }
    }
    .table-quantity-column {
      width: 100px !important;
      &:hover {
        cursor: pointer;
      }
    }
    .table-parts-row-orange {
      background-color: orange;
    }
    .thead {
      background-color: lightgreen;
    }
    &-button {
      background-color: lightgreen;
      border-radius: 5px;
    }
    &-action-button {
      background-color: lightskyblue;
      border-radius: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}

//users
.container-table-users {
  min-height: 80vh;
  background-color: white;
  padding-top: 10px;
  overflow-x: auto;
  padding: 10px;
  .rc-pagination {
    margin: 10px 15px 10px 15px;
    &-item-active {
      background-color: lightgreen !important;
    }
  }
  .loading-spinner {
    height: 100%;
    text-align: center;
    margin: 20% 0;
  }
  .user-button {
    background-color: lightgreen;
    padding: 5px;
    border: 2px solid grey;
    border-radius: 5px;
    margin-bottom: 5px;
    height: 38px;
    margin-right: 5px;
  }
  .table {
    &-bordered {
      border: 2px solid grey !important;
      td,
      th {
        border: 1px solid grey !important;
      }
    }
    .thead {
      background-color: lightgreen;
    }
    &-button {
      background-color: lightgreen;
      border-radius: 5px;
    }
    &-action-button {
      background-color: lightskyblue;
      border-radius: 5px;
    }
  }
}

// department
.container-department {
  min-height: 80vh;
  background-color: white;
  padding: 10px;
  text-align: center;
  .management,
  .systems,
  .departments {
    text-align: left;
    .left-indent {
      padding-left: 20px;
    }
  }
}

//lubrication
.container-lubrication {
  min-height: 80vh;
  background-color: white;
  padding: 10px;
  overflow-x: auto;
  .lubrication-conveyor-button {
    width: 100px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: green;
    margin-right: 10px;
    margin-bottom: 10px;
    color: black;
    &:hover {
      background-color: greenyellow;
    }
  }
  .list-motors-button {
    border: 1px solid black;
    border-radius: 5px;
    background-color: red;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

// should be inside className for motor-report-modal!
.icon-pdf {
  margin: 0 25%;
  color: #ea4c3a;
}
//conveyor
.conveyor-container {
  text-align: center;
  min-height: 80vh;
  background-color: white;
  padding: 5px;
  .motor-row {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
  }
  .motor-container {
    text-align: left;
    padding-left: 2px;
    background-color: rgb(230, 225, 225);
    flex: 1;
    margin: 2px;
    width: 50%;
    border: 1px solid black;
    .motor-headline {
      font-weight: bold;
      position: relative;
      p {
        margin-bottom: 2px;
      }
      .motor-built-in {
        position: absolute;
        right: 0px;
      }
    }
    .motor-data-row {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      .motor-data {
        width: 40%;
        flex: 1;
      }
      .lubrication-data {
        width: 60%;
        flex: 1;
        position: relative;
        .motor-image {
          border: 1px solid black;
          min-width: 200px;
          min-height: 200px;
          max-width: 100%;
        }
        .motor-reports-container {
          margin-top: 10px;
          position: absolute;
          right: 2px;
          .motor-reports-button {
            background-color: lightgreen;
            border-radius: 5px;
          }
        }
        .motor-edit-data-container {
          position: absolute;
          right: 2px;
          bottom: 2px;
          .motor-edit-data-button {
            background-color: lightgreen;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

label {
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
}

.form-input {
  display: block;
  width: 300px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-input-file {
  width: 300px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: normal;
}

.date {
  text-align: left;
  .react-datepicker-wrapper {
    input {
      width: 300px;
    }
  }
}

.error {
  color: red;
  text-align: left;
}

.modal-header {
  background-color: lightgreen;
}

.modal-body {
  padding: 0;
}

.modal-service-body-header {
  padding: 10px;
  font-weight: bold;
}

.modal-form-footer {
  text-align: left;
  .delete {
    margin-left: 40px;
  }
  .save {
    float: right;
    margin-right: 15px;
  }
}

.container-image-view {
  .image-view {
    width: 100%;
    max-height: 700px;
  }
}

@include mobile-tablet-range {
  .container-parts {
    .table {
      &-action-button {
        margin-bottom: 5px;
      }
    }
    .container-search-parts-filter {
      margin-bottom: 10px;
      .container-search-form {
        display: inline;
      }
    }
  }
  .container-landing {
    .image {
      .carousel-inner {
        height: 310px !important;
      }
      .logo-image {
        width: 100%;
        min-height: 200px;
        max-height: 200px;
      }
    }
    .sign {
      .btn {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .container-services {
    .table {
      &-action-button {
        margin-bottom: 5px;
      }
    }
    .container-search-parts-filter {
      margin-bottom: 10px;
      .container-search-form {
        display: inline;
      }
    }
  }
  .container-image-view {
    .image-view {
      width: 100%;
      max-height: 500px;
    }
  }
  .conveyor-container {
    .motor-row {
      flex-direction: column;
      width: 100%;
      min-height: 1000px;
      .motor-container {
        margin: 5px;
        width: 100%;
        border: 1px solid black;
        height: 400px;
        .motor-headline {
          font-weight: bold;
          position: relative;
          p {
            margin-bottom: 2px;
          }
          .motor-built-in {
            position: absolute;
          }
        }
      }
    }
  }
}

@include mobile-small-range {
  .form-input {
    width: 100%;
  }
  .form-input-file {
    width: 162px;
    word-wrap: break-word;
  }
  .container-search-form {
    display: inline;
  }
  .date {
    .react-datepicker-wrapper {
      input {
        width: 164px;
      }
    }
  }
  .modal-form-footer {
    .save {
      margin-right: 0px;
    }
  }
  .page-not-found {
    &-with-nav-bar {
      width: 360px;
      padding: 15px;
      h1 {
        font-size: 30px;
        font-weight: 900;
        text-align: center;
      }
    }
  }
  .container-landing {
    .image {
      .carousel-inner {
        height: 230px !important;
      }
    }
  }
  .container-image-view {
    .image-view {
      width: 100%;
      max-height: 500px;
    }
  }
  .container-form-edit-user {
    .form-group {
      select {
        width: 100%;
      }
    }
  }
  .conveyor-container {
    overflow-x: hidden;
    .motor-row {
      .motor-container {
        .motor-headline {
          p {
            .motor-built-in {
              position: absolute;
              left: 0px;
              top: 20px;
              margin: 5px;
            }
          }
        }
        .motor-data-row {
          .motor-data {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .container-legend-sticky-wrapper {
    position: absolute;
    background-color: white;
    line-height: 1;
    padding-bottom: 7px;
    font-size: 16px;
    p {
      margin: 2px 2px 2px 2px;
      padding-right: 25px;
      position: relative;
      span {
        position: absolute;
        right: 0px;
        top: -7px;
      }
    }
    z-index: 10;
  }
  .container-legend-sticky .container-legend-sticky-inner {
    position: fixed;
    background-color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 7px;
    line-height: 1;
    p {
      margin: 2px 2px 2px 2px;
      padding-right: 25px;
      position: relative;
      span {
        position: absolute;
        right: 0px;
        top: -7px;
      }
    }
    z-index: 10;
  }
}
